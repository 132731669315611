import * as React from "react"
import { Box, Heading, VStack } from "@chakra-ui/react"
import Head from "next/head"

import { Layout } from "components/Layout"
import { LoginForm } from "components/LoginForm"
import { withNoAuth } from "components/hoc/withNoAuth"

function Login() {
  return (
    <VStack pt={20}>
      <Head>
        <title>Kunstbende - Login</title>
      </Head>
      <Heading w={["100%", 600]} as="h1" textAlign="center">
        Login
      </Heading>
      <Box w={["100%", 400]}>
        <LoginForm />
      </Box>
    </VStack>
  )
}
Login.getLayout = (page: React.ReactElement) => <Layout>{page}</Layout>
export default withNoAuth(Login)
