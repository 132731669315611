import * as React from "react"
import { gql, useApolloClient } from "@apollo/client"
import { Button, Center, Flex, Link, Spinner, Stack, Text } from "@chakra-ui/react"
import * as Sentry from "@sentry/nextjs"
import NextLink from "next/link"
import { useRouter } from "next/router"

import { ACCESS_TOKEN, REFRESH_TOKEN_KEY } from "lib/config"
import { LoginInput, MeDocument, Role, useLoginMutation } from "lib/graphql"
import { useForm } from "lib/hooks/useForm"
import { useMe } from "lib/hooks/useMe"
import yup from "lib/yup"
import { Form } from "components/Form"
import { FormError } from "components/FormError"
import { Input } from "components/Input"

const _ = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      user {
        ...Me
      }
      token
      refreshToken
    }
  }
`

const LoginSchema = yup.object().shape({
  email: yup.string().required().email("Ongeldig e-mailadres").required("Verplicht"),
  password: yup.string().required().min(8, "Moet ten minste 8 karakaters bevatten"),
})

interface Props {
  handleSubmit?: () => void
}

export function LoginForm({ handleSubmit }: Props) {
  const router = useRouter()
  const client = useApolloClient()
  const { me, loading } = useMe({ fetchPolicy: "network-only" })
  const [login, { loading: loginLoading }] = useLoginMutation()

  const form = useForm({ schema: LoginSchema })

  const onSubmit = (data: LoginInput) => {
    return form.handler(() => login({ variables: { data } }), {
      onSuccess: async (res, toast) => {
        try {
          localStorage.setItem(ACCESS_TOKEN, res.login.token)
          await fetch("/api/login", {
            method: "post",
            body: JSON.stringify({
              [REFRESH_TOKEN_KEY]: res.login.refreshToken,
            }),
          })
          client.writeQuery({ query: MeDocument, data: { me: res.login.user } })
          // router.replace(res.login.user.role === Role.Admin ? "/admin" : "/")
          handleSubmit?.()
        } catch {
          localStorage.removeItem(ACCESS_TOKEN)
          toast({
            title: "Fout bij inloggen",
            description: "Vernieuw de pagina en probeer het opnieuw. Wij zijn op de hoogte gesteld",
            status: "error",
          })
        }
      },
      onServerError: async (message, toast) => {
        Sentry.captureMessage(message)
        localStorage.removeItem(ACCESS_TOKEN)
        await fetch("/api/logout", { method: "post" })
        toast({
          title: "Fout bij inloggen",
          description: "Vernieuw de pagina en probeer het opnieuw. Wij zijn op de hoogte gesteld",
          status: "error",
        })
      },
    })
  }

  React.useEffect(() => {
    if (loading || !me) return
    router.replace(me.role === Role.Admin ? "/admin/gebruikers" : "/")
  }, [loading, me, router])

  if (loading || me)
    return (
      <Center minH="80vh">
        <Spinner />
      </Center>
    )
  return (
    <Form onSubmit={onSubmit} {...form}>
      <Stack spacing={2} p={2}>
        <Input name="email" label="Email" placeholder="ryan@email.com" isRequired />
        <Input name="password" label="Wachtwoord" type="password" placeholder="********" isRequired />
        <Button colorScheme="yellow" type="submit" w="100%" isLoading={loginLoading}>
          Login
        </Button>
        <Flex justify="flex-end">
          <Link as={NextLink} passHref href="/forgot-password">
            Wachtwoord vergeten?
          </Link>
        </Flex>
        <Text textAlign="center">of</Text>
        <Button as={NextLink} passHref href="/inschrijven" variant="outline">
          Account aanmaken
        </Button>
        <FormError />
      </Stack>
    </Form>
  )
}
